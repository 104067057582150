<template>
    <section id="Embed" class="services">
      <div class="container">

        <div class="section-title">
          <h4>Choose to work with the finest team of dedicated embedded engineers.</h4>
       </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="embedimage"/>
              <h4 class="embedservice">{{ obj.header }}</h4>
              <p class="embedservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/accounting.png',
        header: 'Improving UI',
        caption: 'In any system developed, it is important that both users and the company benefits. The improvement of user interface is taken into consideration so that there is no error in the final product and when it is used for its benefits.'
      },
      {
        path: 'background/problem.png',
        header: 'Fixing up a problem',
        caption: 'Developing an error free tested software and hardware when it comes to an embedded system is a complete function. By constantly updating its standard by coding the process and recoding is part of fixing an embedded system.'
      },
      {
        path: 'background/Unique.png',
        header: 'Unique characteristics',
        caption: 'Constantly developed embedded systems tend to have unique characteristics. They are focused and designed on their problem solving techniques assisting daily lives of human beings.'
      }
    ]

  })
    
}
</script>
<style>
  .services .icon-box img {
    float: left;
    margin-top: 10px;
  }
img.embedimage {
    width: 15%;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Embed {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 10px;
}
.embedservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>
<template>
    <Header/>
    <Embeddedheader/>
    <Embeddedservice/>
    <Embeddedicons/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Embeddedservice from '@/components/Embeddedservice.vue'
import Embeddedheader from '@/components/Embeddedheader.vue'
import Embeddedicons from '@/components/Embeddedicons.vue'


export default {
  components: {
     Header,
    Footer,
    Embeddedservice,
    Embeddedheader,
    Embeddedicons
  }
}
</script>
<template>
    <section id="Embed" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Experiencing Ease in a brand new way</h2>
          <p style="padding-top:20px">Embedded systems constantly change the face of tomorrow, working with pre-coded systems and machines. We design embedded systems in all fields such as medical, electronics and manufacturing to name a few. In all our recent approaches what we have found in common is that people in the present world require engineered solutions that not only give product satisfaction, but also require a life- based approach where machines assist lives.</p>
          <p>This is where embedded engineering functions by pre-coding machines and systems. We assure not only product satisfaction but also deliver product tailored to meet all your requirements.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="embedhead">{{ obj.header }}</h4>
              <h6 style="font-size:14px;line-height: 20px;">{{obj.subtitile}}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/CHALLENGING-PROJECTS.jpg',
        header: 'CHALLENGING PROJECTS',
        subtitile: 'Innovative field of engineering',
        caption: 'We work with the popular micro-controller based projects, these include computer chips designed to perform a certain task in a machine. Our Engineers work with both the hardware and the software component.'
      },
      {
        path: 'services/LATEST-TECHNOLOGY.jpg',
        header: 'LATEST TECHNOLOGY',
        subtitile: 'Constantly Learning',
        caption: 'Embedded engineering is a growing field, with advanced API’s available for each application developed, it is considered still necessary to incorporate new skills through learning. Engineers who design it are the only ones who control its functioning.'
      },
      {
        path: 'services/SKILLED-PROFESSIONALS.jpg',
        header: 'SKILLED PROFESSIONALS',
        subtitile: 'Help deliver new engineered solutions',
        caption: 'Our skilled experts work with challenging projects, they are highly innovative with every step, testing software and applications to perfection.'
      },
      {
        path: 'services/TESTING-APPLICATIONS.jpg',
        header: 'TESTING APPLICATIONS',
        subtitile: 'Improved performance',
        caption: 'After developing an embedded engineered system, to render a bug-free application, it is important that we test both hardware and software of the system to reduce costs in production and to improve overall performances.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Embed {
    padding: 50px 30px 10px 30px;
}

.services .icon-box .embedhead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>